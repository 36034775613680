import { render, staticRenderFns } from "./List.vue?vue&type=template&id=6523fc05&"
import script from "./List.vue?vue&type=script&lang=js&"
export * from "./List.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CartItem: require('/opt/build/repo/components/Cart/Item.vue').default,OrderAddToCartForm: require('/opt/build/repo/components/Order/AddToCartForm.vue').default})
