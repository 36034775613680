//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: ["option", "value"],
    data: function () {
        let selectedStarterValue =
            this.option.optionType === "one"
                ? ["set", "add"].includes(this.option.priceModel)
                    ? this.choiceValue(this.option.choices[0]).value
                    : null
                : [];
        this.$emit("input", selectedStarterValue);

        return {
            selected: selectedStarterValue,
        };
    },
    watch: {
        selected: function (newValue, oldValue) {
            this.$emit("input", newValue);
        },
    },
    computed: {
        choices: function () {
            return this.option.choices.map((choice) => {
                return this.choiceValue(choice);
            });
        },
    },
    methods: {
        choiceValue: function (choice) {
            let price = " ";
            if (this.option.priceModel !== "none") {
                price += "(";
                if (this.option.optionType === "multiple") {
                    price += "+";
                }
                price += `$${choice.price})`;
            }
            return {
                text: `${choice.name}${price}`,
                value: { name: choice.name, price: choice.price },
            };
        },
    },
};
