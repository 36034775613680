//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import { v4 as uuidv } from "uuid";

export default {
    props: ["id", "menuItem", "editItem"],
    data: function () {
        return {
            price: this.currentMenuItem?.price,
            selectedOptions: {},
            quantity: 1,
            specialRequests: "",
        };
    },
    watch: {
        menuItem: {
            deep: true,
            handler: function () {
                if (!this.editItem) {
                    if (this.menuItem?.options) {
                        let options = this.menuItem.options.map((option) => {
                            let opt = {};
                            opt[option.name] = null;
                            return opt;
                        });
                        if (options.length) {
                            this.selectedOptions = Object.assign(...options);
                        }
                    }
                    this.quantity = 1;
                    this.price = this.menuItem.price;
                }
            },
        },
        editItem: {
            deep: true,
            handler: function () {
                this.price = this.editItem.price;
                this.selectedOptions = JSON.parse(
                    JSON.stringify(this.editItem.options)
                );
                this.quantity = this.editItem.quantity;
                this.specialRequests = this.editItem.specialRequests;
            },
        },
        selectedOptions: {
            deep: true,
            handler: function () {
                if (Object.values(this.selectedOptions).length > 0) {
                    let basePrice = this.currentMenuItem?.price;
                    let adds = 0;
                    this.currentMenuItem.options.forEach((option) => {
                        let optionValue = this.selectedOptions[option.name];
                        if (optionValue && option.priceModel != "none") {
                            if (option.priceModel == "set") {
                                basePrice = parseFloat(optionValue.price);
                            } else if (option.optionType == "one") {
                                adds += parseFloat(optionValue.price);
                            } else {
                                Object.values(optionValue).forEach((value) => {
                                    adds += parseFloat(value.price);
                                });
                            }
                        }
                    });
                    this.price = basePrice + adds;
                }
            },
        },
    },
    computed: {
        ...mapGetters({ images: "image/all", menuItems: "menu-item/all" }),
        image: function () {
            return this.images[this.currentMenuItem.image];
        },
        currentMenuItem: function () {
            this.selectedOptions = {};
            if (this.editItem) {
                return this.menuItems[this.editItem.menuItem];
            } else {
                return this.menuItem;
            }
        },
    },
    methods: {
        submit() {
            let id = this.editItem ? this.editItem.id : uuidv();
            let data = {
                id: id,
                name: this.currentMenuItem.name,
                menuItem: this.currentMenuItem.id,
                quantity: parseInt(this.quantity),
                price: this.price,
                options: JSON.parse(JSON.stringify(this.selectedOptions)),
                specialRequests: this.specialRequests,
            };
            if (this.editItem) {
                let payload = {
                    data: data,
                    id: id,
                };
                this.$store.dispatch("cart/update", payload);
            } else {
                this.$store.dispatch("cart/add", data);
            }
            this.$root.$emit("bv::hide::modal", this.id);
        },
    },
};
